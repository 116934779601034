/* eslint-disable func-names */
const searchQuery = `
  query Search($query: String!, $keywords: String!) {
    unitCategories(
      filter: {
        where: {
          _or: [
            { slug: { _iLike: $query } }
            { title: { _iLike: $query } }
            { keywords: { _iLike: $query } }
          ]
        }
        limit: 20
      }
    ) {
      id
      slug
      title
      iconName
    }
    unitConversions(
      filter: {
        where: {
          _or: [
            { slug: { _iLike: $query } }
            { title: { _iLike: $query } }
            { keywords: { _iLike: $keywords } }
          ]
        }
        limit: 20
      }
    ) {
      id
      slug
      keywords
      title
      unitCategory {
        id
        slug
      }
    }
  }
`;

const DESKTOP_MEDIA = 1280;
const button = document.getElementById('dropdownButton');
const dropdownMenu = document.getElementById('dropdownMenu0');
const mobileUnitMenuButton = document.getElementById('mobileUnitMenuButton');
const mobilePdfMenuButton = document.getElementById('dropdownMenu1');
const mobilePdfMenuButton2 = document.getElementById('dropdownButton2');

//Unit
const ul = document.getElementById('converterList');
const ul2 = document.getElementById('converterList2');
const buttons = ul.querySelectorAll('button');
//Pdf
const button2 = document.getElementById('dropdownButton1');
const dropdownMenu2 = document.getElementById('dropdownMenu1');

const closeDropdownMenu = (event, dropdownMenu) => {
  const isClickInsideMenu = dropdownMenu.contains(event.target);
  const isClickInsideButton = event.target === button || event.target === button2;

  if (!isClickInsideMenu && !isClickInsideButton) {
    if (window.innerWidth >= DESKTOP_MEDIA) {
      dropdownMenu.classList.add('xl:hidden');
    }
  }
};

const toggleDropdownMenu = (button, dropdownMenu, otherDropdownMenu) => {
  button.addEventListener('click', function (e) {
    e.preventDefault();
    e.stopPropagation();

    if (!otherDropdownMenu.classList.contains('xl:hidden')) {
      otherDropdownMenu.classList.add('xl:hidden');
      otherDropdownMenu.previousElementSibling.querySelector('svg').classList.remove('rotate-180');
    }

    dropdownMenu.classList.toggle('xl:hidden');
    this.querySelector('svg').classList.toggle('rotate-180');
  });
};

if (window.innerWidth <= DESKTOP_MEDIA) {
  ul.classList.add('hidden');
  ul2.classList.add('hidden');
  const divs = ul.querySelectorAll('div');

  divs.forEach((div) => {
    div.classList.add('hidden');
  });
  mobileUnitMenuButton.addEventListener('click', function () {
    ul.classList.toggle('hidden');
    this.querySelector('svg').classList.toggle('rotate-180');
    mobileUnitMenuButton.classList.toggle('bg-blue-600');
    mobileUnitMenuButton.classList.toggle('text-white');
  });
  mobilePdfMenuButton.addEventListener('click', function () {
    ul2.classList.toggle('hidden');
    this.querySelector('svg').classList.toggle('rotate-180');
    mobilePdfMenuButton2.classList.toggle('bg-blue-600');
    mobilePdfMenuButton2.classList.toggle('text-white');
  });
}

if (button && dropdownMenu && ul) {
  toggleDropdownMenu(button, dropdownMenu, dropdownMenu2);

  buttons.forEach((button) => {
    button.addEventListener('click', function () {
      buttons.forEach((btn) => {
        btn.setAttribute('tabindex', btn === this ? '0' : '-1');

        const div = btn.nextElementSibling;

        if (window.innerWidth <= DESKTOP_MEDIA) {
          btn.setAttribute('tabindex', '0');

          if (btn === this) {
            btn.querySelector('span:nth-child(3)').classList.toggle('rotate-180');
            btn.querySelector('span:nth-child(3)').classList.toggle('text-blue-600');
            div.classList.toggle('hidden');
          }
        }
        btn.classList.toggle('bg-gray-100', btn === this);

        if (btn.getAttribute('tabindex') === '0') {
          if (window.innerWidth >= DESKTOP_MEDIA) {
            div.classList.remove('hidden');
          }
        } else {
          div.classList.add('hidden');
        }
      });
    });
  });
} else {
  // eslint-disable-next-line no-console
  console.error('Button, dropdownMenu, or ul not found.');
}

if (button2 && dropdownMenu2) {
  toggleDropdownMenu(button2, dropdownMenu2, dropdownMenu);
} else {
  // eslint-disable-next-line no-console
  console.error('Button, dropdownMenu, or ul not found.');
}

document.addEventListener('click', (event) => {
  closeDropdownMenu(event, dropdownMenu);
  closeDropdownMenu(event, dropdownMenu2);
});

const menuToggle = document.getElementById('menu-toggle');
const svg1 = document.getElementById('svg1');
const svg2 = document.getElementById('svg2');
const menuItems = document.getElementById('menu-items');

const toggleMenu = () => {
  if (window.innerWidth < DESKTOP_MEDIA) {
    menuItems.classList.toggle('hidden');
    menuItems.classList.toggle('open');

    if (menuItems.classList.contains('open')) {
      svg1.classList.add('hidden');
      svg2.classList.remove('hidden');
    } else {
      svg1.classList.remove('hidden');
      svg2.classList.add('hidden');
    }
  } else {
    menuItems.classList.add('hidden');
  }
};

menuToggle.addEventListener('click', toggleMenu);

const searchButton = document.getElementById('search-button');
const searchModal = document.getElementById('search-modal');
const searchModalCloseButton = document.getElementById('search-modal-close');
const searcModalBody = document.getElementById('search-modal-body');
const closeModalButton = document.getElementById('closeModalButton');

searchButton.addEventListener('click', () => {
  searchModal.classList.remove('hidden');
});

searchModalCloseButton.addEventListener('click', () => {
  searchModal.classList.add('hidden');
});

const closeSearchModal = (event, searcModalBody) => {
  const isClickInsideSearchModal = searcModalBody.contains(event.target);

  if (
    (!isClickInsideSearchModal && !(event.target === searchButton)) ||
    event.target === closeModalButton ||
    event.target === searchModalCloseButton
  ) {
    searchModal.classList.add('hidden');
  }
};

searchModal.addEventListener('click', (event) => {
  closeSearchModal(event, searcModalBody);
});

// Modal search input
const fetchData = async (query) => {
  try {
    const response = await fetch('https://api.oneconvert.org/graphql', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        query: searchQuery,
        variables: {
          query: `%${query}%`,
          keywords: `%${query.replace(' to ', ',')}%`,
        },
      }),
    });
    const responseData = await response.json();

    return responseData;
  } catch (error) {
    throw new Error(error.message || 'An error occurred');
  }
};

function calculatePercentageMatch({ slug, title, keywords }, searchWords) {
  const slugWords = slug.toLowerCase().split(/[ -]/u);
  const titleWords = title.toLowerCase().split(' ');
  const keyWords = keywords ? keywords.toLowerCase().split(',') : [];
  const MAX_PERCENT = 100;

  const searchSlug = searchWords.toLowerCase();

  let matchCount = 0;

  slugWords.forEach((word) => {
    if (searchSlug.includes(word)) {
      matchCount++;
    }
  });
  titleWords.forEach((word) => {
    if (searchSlug.includes(word)) {
      matchCount++;
    }
  });
  keyWords.forEach((word) => {
    if (searchSlug.includes(word)) {
      matchCount++;
    }
  });

  const percentMatch = (matchCount / (slugWords.length + titleWords.length + keyWords.length)) * MAX_PERCENT;

  return percentMatch;
}

const searchInput = document.getElementById('searchInput');

function handleFetchedData(dataArray) {
  const ulElement = document.getElementById('searchResult');
  const spanElement = document.getElementById('spanElement');

  ulElement.innerHTML = '';

  if (dataArray.length > 0) {
    spanElement.classList.add('hidden');
  } else if (dataArray.length === 0) {
    spanElement.textContent = 'No data found';
    spanElement.classList.remove('hidden');
  } else {
    spanElement.classList.remove('hidden');
  }

  dataArray.forEach((item) => {
    const liElement = document.createElement('li');
    const aElement = document.createElement('a');

    const categorySlug = item.unitCategory ? `/${item.unitCategory.slug}` : '';

    aElement.setAttribute('title', item.title);
    aElement.setAttribute('class', 'block rounded p-2 pr-2 md:pr-10 border relative border-gray-200');
    aElement.setAttribute('href', `https://oneconvert.com/unit-converters${categorySlug}/${item.slug}`);
    aElement.textContent = item.title;

    liElement.appendChild(aElement);
    ulElement.appendChild(liElement);
  });
}

searchInput.addEventListener('input', (event) => {
  const query = event.target.value.trim();
  const svgIcon = document.getElementById('deleteSearchValue');

  if (query.length > 0) {
    svgIcon.classList.remove('hidden');
  } else {
    svgIcon.classList.add('hidden');
  }

  function clearInput() {
    searchInput.value = '';
    svgIcon.classList.add('hidden');
    handleFetchedData([]);
  }

  svgIcon.addEventListener('click', clearInput);

  fetchData(query)
    .then((response) => {
      const responseData = [...response.data.unitCategories, ...response.data.unitConversions];

      responseData.forEach((obj) => {
        const percentageMatch = calculatePercentageMatch(obj, query);

        obj.percentageMatch = percentageMatch;
      });

      const sortSearchPages = responseData.sort((a, b) => b.percentageMatch - a.percentageMatch);

      handleFetchedData(sortSearchPages);
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
});
